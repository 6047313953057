const authorizeResourceAllocation = {
	id: 'authorizeResourceAllocation',
	selectionType: 'single',
	label: 'actions.resourceallocation.authorize',
	functionality: 'ACTION_AUTHORIZE_RESOURCE_ALLOCATION',
	checkAvailability: function (registries) {
		const editableStatus = ['PL'];
		if (registries.length > 0) {
			const isEditable = editableStatus.some((element) => element === registries[0].resourceallocationstatus);

			if (registries[0] && isEditable) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		// to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-authorizeResourceAllocation-' + model.name + '-show', row);
	}
};

const initiateResourceAllocation = {
	id: 'initiateResourceAllocation',
	selectionType: 'single',
	label: 'actions.resourceallocation.initiate',
	functionality: 'ACTION_INITIATE_RESOURCE_ALLOCATION',
	checkAvailability: function (registries) {
		const editableStatus = ['AU'];
		if (registries.length > 0) {
			const isEditable = editableStatus.some((element) => element === registries[0].resourceallocationstatus);

			if (registries[0] && isEditable) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		// to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-initiateResourceAllocation-' + model.name + '-show', row);
	}
};

const endResourceAllocation = {
	id: 'endResourceAllocation',
	selectionType: 'single',
	label: 'actions.resourceallocation.end',
	functionality: 'ACTION_END_RESOURCE_ALLOCATION',
	checkAvailability: function (registries) {
		const editableStatus = ['PR'];
		if (registries.length > 0) {
			const isEditable = editableStatus.some((element) => element === registries[0].resourceallocationstatus);

			if (registries[0] && isEditable) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		// to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-endResourceAllocation-' + model.name + '-show', row);
	}
};

const cancelResourceAllocation = {
	id: 'cancelResourceAllocation',
	selectionType: 'single',
	label: 'actions.resourceallocation.cancel',
	functionality: 'EDIT_RESOURCEALLOCATION',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			if (
				registries[0] &&
				(registries[0].resourceallocationstatus == 'PL' ||
					registries[0].resourceallocationstatus == 'PR ' ||
					registries[0].resourceallocationstatus == 'AU')
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		// to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-cancelResourceAllocation-' + model.name + '-show', row);
	}
};

const validateResourceAllocation = {
	id: 'validateResourceAllocation',
	selectionType: 'single',
	label: 'actions.resourceallocation.validate',
	functionality: 'ACTION_VALIDATE_RESOURCE_ALLOCATION',
	checkAvailability: function (registries) {
		const editableStatus = ['FI'];
		if (registries.length > 0) {
			const isEditable = editableStatus.some((element) => element === registries[0].resourceallocationstatus);

			if (registries[0] && isEditable) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		// to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-validateResourceAllocation-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [
		authorizeResourceAllocation,
		initiateResourceAllocation,
		endResourceAllocation,
		cancelResourceAllocation,
		validateResourceAllocation
	],
	formactions: [
		authorizeResourceAllocation,
		initiateResourceAllocation,
		endResourceAllocation,
		cancelResourceAllocation,
		validateResourceAllocation
	]
};
